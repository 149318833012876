@tailwind base;
@tailwind components;
@tailwind utilities;

.limit-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
   }

@layer components   {

    

    .order-selector {
        @apply
        text-white rounded-full p-2 shadow-xl w-40
    }

    .order-row-item {
        @apply
        px-6 py-4
    }

    .slide-in {
        @apply
        translate-x-0 opacity-100
    }
    
    .glass-gradient {
        background: linear-gradient(to right bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.4))
    }

    .fade-out {
        background: linear-gradient(to right bottom, rgba(0,0,0,0.7))
    }

    .reflection {
        -webkit-box-reflect: below 25px linear-gradient(transparent,#0009);
    }

    .tonedButton {
        @apply
        relative transition font-bold ease-linear rounded-md px-5 py-3
        border border-black cursor-pointer shadow-inner hover:shadow-black
        active:shadow-black

    }

    .spinning {
        background-color: #121619;
        padding-right: 40px;
    }
    .spinning:after {
        content: '';
        position: absolute;
        right: 6px;
        top: 50%;
        width: 0;
        height: 0;
        box-shadow: 0px 0px 0 1px brightness(80%);
        position: absolute;
        border-radius: 50%;
        animation: rotate360 .5s infinite linear, exist .1s forwards ease;
    }

    
    .spinning:before {
        content: "";
        width: 0px;
        height: 0px;
        border-radius: 50%;
        right: 6px;
        top: 50%;
        position: absolute;
        border: 2px solid brightness(80%);
        border-right: 3px solid #F6AE2D;
        animation: rotate360 .5s infinite linear, exist .1s forwards ease ;
        
    }

    @keyframes rotate360 { 
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes exist { 
        100% {
            width: 25px;
            height: 25px;
            margin: -12px 20px 0 0;
        }
    }

    .animate-fade-in-up {
        opacity: 0;
        transform: translateY(50px);
        animation: fadeInUp 0.5s ease-in-out forwards;
    }

    @keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
    }



}